#appointment-book-container {
  h1,
  h2,
  h3 {
    font-family: $font--playfair-display !important;
  }
  [class*='subhead'] {
    font-family: $font--roboto !important;
  }
}

.appt-book {
  .appt-book-page-header {
    @include breakpoint($landscape-up) {
      padding: 0 5%;
    }
    @include breakpoint($landscape-down) {
      padding: 0 1%;
    }
  }
  #counters {
    &.appt-book-location-selector {
      @include breakpoint($medium-up) {
        padding-top: 250px;
      }
      .location-select {
        @include breakpoint($medium-up) {
          width: 100%;
        }
      }
    }
  }
  .appt-book-section-content {
    .appt-book-location__signin-text {
      p {
        margin: 25% 10%;
      }
    }
  }
  .ui-select {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .appointment-select {
    .appointments-container {
      .calendar-wrapper {
        table {
          &.calendar-view {
            th {
              width: calc(100% / 7);
            }
          }
        }
      }
      .book-appt-container {
        .registration__email-list {
          width: 48%;
          padding: 0 1%;
        }
        .registration__privacy-policy-list {
          width: 48%;
          padding: 0 1%;
          float: left;
          span {
            font-family: $font--roboto;
            font-size: 12px;
            font-variant: normal;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 18px;
          }
        }
        .mobile-phone-fieldset,
        input {
          @include breakpoint($medium-landscape-only) {
            max-width: 450px;
          }
        }
        fieldset {
          &.mobile-phone-fieldset {
            .appt-book-mobile {
              @include breakpoint($landscape-down) {
                width: 82%;
              }
            }
          }
        }
      }
      .book-appt-container input,
      .sign-in-container input {
        @include breakpoint($tablet-only) {
          margin-right: 0;
        }
      }
      .sign-in-container {
        .sign-in-form-container {
          input {
            &.login__submit {
              margin-left: 0%;
              width: auto;
              border-radius: 5px;
            }
            @include breakpoint($tablet-only) {
              margin-right: 0;
              &.login__email {
                width: 40%;
              }
              &.login__password {
                width: 30%;
              }
            }
          }
        }
      }
    }
  }
  #confirmation {
    .confirmation-container {
      .appointment-info-content {
        .appointment-info-detail {
          h4 {
            line-height: 12px;
            padding-top: 30px;
            min-height: 65px;
          }
          span {
            font-family: $font--roboto;
            padding-top: 10px;
          }
        }
        .total-cost {
          width: 19%;
        }
      }
    }
  }
}

.appt-book-overlay {
  &.appt-book-book-conflict-overlay,
  &.appt-book-services-overlay,
  &.appt-book-services-unavailabe-overlay {
    .overlay-content-container {
      height: auto;
      .overlay-content {
        height: auto;
      }
    }
  }
  .overlay-content-container {
    .overlay-content {
      height: auto;
    }
    .overlay-close {
      top: 12%;
      right: 7%;
    }
  }
}

#my-appointments-current {
  .appointment-info-detail {
    h4 {
      line-height: 12px;
      padding-top: 30px;
      min-height: 65px;
    }
    span {
      font-family: $font--roboto;
      padding-top: 10px;
    }
  }
}

#appointments {
  .appt-book-overlay {
    .overlay-content-container {
      .overlay-content {
        height: auto;
      }
      .overlay-close {
        top: 11%;
      }
    }
  }
  .appt-book-page-header-content {
    padding: 50px 0 0 0;
  }
}
